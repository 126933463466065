var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"smslist-wrappper"},[(_vm.contactSelect != undefined && _vm.contactSelect != null)?[_c('div',{staticClass:"d-flex"},[_c('div',[_c('h3',{staticClass:"mt-2 ml-2"},[_vm._v(" "+_vm._s("To: " + _vm.contactSelect.name != undefined ? _vm.contactSelect.name : _vm.contactSelect.number)+" ")]),_c('h4',{staticClass:"ma-1 text--secondary"},[_vm._v(" "+_vm._s(_vm.contactSelect.number)+" ")])]),_c('v-spacer'),( _vm.contactSelect.customer_uuid)?[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ma-1",attrs:{"color":"info","text":""},on:{"click":function($event){return _vm.gotoPatient(_vm.contactSelect.customer_uuid)}}},on),[_c('v-icon',[_vm._v("mdi-account")])],1)]}}],null,false,814136278)},[_c('span',[_vm._v("Customer Details")])])]:_vm._e()],2),_c('v-divider')]:_vm._e(),[_c('div',{staticClass:"message-list-container   \n      "},[_vm._v(" "+_vm._s(/** List messages */)+" "),(_vm.contactSelect == undefined || _vm.contactSelect == null)?_c('div',{staticClass:"d-flex flex-column align-start justify-start px-2 py-2"},[_vm._v(" Select contact ")]):_vm._e(),(_vm.contactSelect != undefined && _vm.contactSelect != null)?_c('div',{ref:"smscontentScroll",staticClass:"smscontent px-2 pb-2"},[(_vm.loadingbtw)?[_c('div',{staticClass:"d-flex justify-center my-4"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1)]:_vm._e(),(_vm.moreSms && !_vm.loadingbtw)?[_c('div',{staticClass:"d-flex justify-center container-more"},[_c('v-btn',{attrs:{"text":"","color":"info"},on:{"click":_vm.getMore}},[_c('v-icon',[_vm._v("mdi-message-processing")]),_vm._v(" more sms")],1)],1),_c('v-divider')]:_vm._e(),_c('div',{staticClass:"message-list "},[_vm._l((_vm.messagesFiltered),function(message,index){return [(_vm.isDiferentMessage(index, _vm.messagesFiltered))?_c('v-sheet',{directives:[{name:"ripple",rawName:"v-ripple"}],key:'s-' + index,staticClass:"mt-2 px-2 d-flex align-center cursor-pointer",class:{
              'justify-end': _vm.isSend(message),
              'ml-auto': _vm.isSend(message),
            },staticStyle:{"min-height":"24px","top":"0","z-index":"1"},attrs:{"color":"var(--crm-background)","rounded":"","small":""}},[_c('div',{staticClass:"d-flex justify-center fill-width"},[_c('span',{staticClass:"text-caption font-weight-bold text-center "},[_vm._v(" "+_vm._s(_vm.formatDate(message.createAt))+" ")])])]):_vm._e(),(_vm.contactSelect != undefined)?_c('message',{key:index,attrs:{"messageProps":Object.assign({}, message,
            {first: _vm.isFirst(index, _vm.messagesFiltered),
            last: _vm.isLast(index, _vm.messagesFiltered),
            middle:
              _vm.isMiddle(index, _vm.messagesFiltered) ||
              (_vm.isFirst(index, _vm.messagesFiltered) &&
                _vm.isFirst(index, _vm.messagesFiltered))})}}):_vm._e()]})],2)],2):_vm._e()])],(_vm.contactSelect != null)?[_vm._v(" "+_vm._s(/* To send messages */)+" "),_c('message-input',{attrs:{"loading":_vm.loadingSendMessage},on:{"send-message":function($event){return _vm.getsmsFiltered(0)}}}),(_vm.loadingbtw)?_c('v-progress-linear',{attrs:{"color":"green accent-3","absolute":"","bottom":"","fixed":"","indeterminate":""}}):_vm._e()]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }